// Add custom extensions for Zepto
// http://blog.pamelafox.org/2011/11/porting-from-jquery-to-zepto.html

// Alias jQuery to Zepto
jQuery = Zepto;

(function($) {
    // outer(Width, Height) support
    ['width', 'height'].forEach(function(dimension) {
        var offset, Dimension = dimension.replace(/./, function(m) { return m[0].toUpperCase() });
        $.fn['outer' + Dimension] = function(margin) {
            var elem = this;
            if (elem) {
                var size = elem[dimension]();
                var sides = {'width': ['left', 'right'], 'height': ['top', 'bottom']};
                sides[dimension].forEach(function(side) {
                    if (margin) size += parseInt(elem.css('margin-' + side), 10);
                });
                return size;
            } else {
                return null;
            }
        };
    });

    $.fn.nextAll = function(s) {
        var $els = $(), $el = this.next();
        while($el.length) {
            if(typeof s === 'undefined' || $el.is(s)) $els = $els.add($el);
            $el = $el.next();
        }
        return $els;
    };

    $.fn.prevAll = function(s) {
        var $els = $(), $el = this.prev();
        while($el.length) {
            if(typeof s === 'undefined' || $el.is(s)) $els = $els.add($el);
            $el = $el.prev();
        }
        return $els;
    };
})(Zepto);